import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const RobertHoward: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/sporting.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Sporting Excellence"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Robert Howard
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Robert Howard" />
    <div className="container py-14">
      <div className="flex flex-col lg:flex-row gap-16 lg:gap-8">
        <div className="flex flex-col gap-4 lg:max-w-[640px]">
          <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl lg:max-w-[80%]">
            ROBERT HOWARD  RBAI’s MOST SUCCESSFUL SWIMMER
          </h3>
          <p>
            The new swimming pool will be named in memory of Robbie Howard
            (Inchmarlo 1960-67 & RBAI 1967-74).
          </p>
          <p>
            Robbie competed at the 1976 Olympics in Montreal, as well as the
            1974 Commonwealth Games in Christchurch, New Zealand and the
            subsequent 1978 games Edmonton, Canada. Already the 100m & 200m
            backstroke champion and Irish senior record holder, Robbie was still
            in sixth form when he made the final in Christchurch, New Zealand.
          </p>
          <p>
            Robbie’s achievements were all the more remarkable given that both
            of his kidneys were obstructed by stones at birth, causing him
            trouble whilst growing up. Following years of dialysis, he had
            unsuccessful transplant and had to return to dialysis. Subsequently
            and tragically Robbie died from complications of this at the age of
            57.
          </p>
          <p>
            Despite his illness, Robbie maintained his passion for swimming and
            great affinity with RBAI, becoming Treasurer of the Transplant Games
            and taking a lead in organising RBAI’s 2010 bicentenary
            celebrations.
          </p>
        </div>
        <div className="lg:max-w-[245px]">
          <img
            width="1000"
            src="/images/2018/03/robert-howard.jpg"
            className="vc_single_image-img attachment-full"
            alt="Robert Howard"
            srcSet="/images/2018/03/robert-howard.jpg 1000w, /images/2018/03/robert-howard-260x300.jpg 260w, /images/2018/03/robert-howard-768x888.jpg 768w, /images/2018/03/robert-howard-886x1024.jpg 886w"
            sizes="(max-width: 1000px) 100vw, 1000px"
            style={{ maxWidth: "100%" }}
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default RobertHoward
